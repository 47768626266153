import { NavigationInstruction, Redirect } from 'aurelia-router';
import Swal from 'sweetalert2';

export class UpsellUtilities {
    public static edrUpsell(navigationInstruction: NavigationInstruction): Redirect {
        Swal.fire({
            width: '40rem',
            html: `
            <h3><span class="text-primary">Cyber</span>Guard <span class="text-primary">EDR (End Point Security)</span></h3>
            <div style="text-align:left" class="pt-2">
                <p>
                  Protect your business computers from harmful malware thanks to our advanced Endpoint Detection & Response (EDR) solution. CyberGuard EDR is optimised for the satellite communications environment and built on the following modules:
                </p>
                <p>
                  <span class="text-primary font-weight-bold">Malware Protection</span> <strong>Signature-based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">MalwareGuard</span> <strong>Machine Learning based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">ExploitGuard</span> <strong>Behavior-based</strong> malware detection
                </p>
                <p>
                  <span class="text-primary font-weight-bold">MalwareScan</span> <strong>Scan USB device</strong> on insert
                </p>
                <br/>
                <p>
                  <strong>For more information, please see the <a class="text-primary" href="https://cdn.marlink.com/cyberguard/CyberGuard-EDR-Info-Sheet.pdf" target="_blank">CyberGuard EDR Info Sheet <i class="far fa-arrow-up-right-from-square"></i></a><br/>
                  If you are interested, please contact your sales representative / KAM</strong>
                </p>
            </div>
            `,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok, Thanks',
        });
        if (navigationInstruction.previousInstruction === null && navigationInstruction.fragment === '/fleet-health-monitor') {
            return new Redirect('/');
        }
        return;
    }

    public static cyberUpsell(navigationInstruction: NavigationInstruction): Redirect {
        // to prevent new user seeing the alert
        if (navigationInstruction.previousInstruction === null && navigationInstruction.fragment === '/') {
            return new Redirect('settings');
        }
        Swal.fire({
            width: '40rem',
            html: `
            <h3><span class="text-primary">Cyber</span>Guard <span class="text-primary">Threat Detection</span></h3>
            <div style="text-align:left" class="pt-2">
                <p>
                  The Threat Detection solution monitors all outbound and inbound vessel traffic 24/7 and consists of the following components:  
                </p>
                <p>
                  <span class="text-primary">Security Information and Event Management (SIEM):</span> detected threats are reported in the CyberGuard Portal and countermeasures may be applied remotely
                </p>
                <p>
                  <span class="text-primary">Security Operations Centre (SOC):</span> managed threat confirmation, hunting and incident support
                </p>
                <p>
                  <span class="text-primary">Managed Security Services Provider (MSSP):</span> fully Marlink-managed threat remediation
                </p>
                <br/>
                <p>
                   <strong>For more information, please see the <a class="text-primary" href="https://cdn.marlink.com/cyberguard/CyberGuard-Threat-Det-Info-Sheet.pdf" target="_blank">CyberGuard Threat Detection Info Sheet <i class="far fa-arrow-up-right-from-square"></i></a><br/>
                  If you are interested, please contact your sales representative / KAM</strong>
                </p>
            </div>
            `,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok, Thanks',
        });

        return new Redirect('settings');
    }

    public static utmUpsell(navigationInstruction: NavigationInstruction): Redirect {
        // to prevent new user seeing the alert
        if (navigationInstruction.previousInstruction === null && navigationInstruction.fragment === '/') {
            return new Redirect('settings');
        }
        Swal.fire({
            width: '40rem',
            html: `
            <h3><span class="text-primary">Cyber</span>Guard <span class="text-primary">UTM (Universal Threat Management)</span></h3>
            <div style="text-align:left" class="pt-2">
                <p>
                  CyberGuard UTM is a next-generation firewall based on Fortigate technology, specifically developed for operation at remote sites. It consists of the following components:  
                </p>
                <p>
                  <span class="text-primary">Protection:</span> Stateful Firewall, Webfilter, Application Control and Intrusion Protection System (IPS)
                </p>
                <p>
                  <span class="text-primary">Network Management:</span> Secure Inter-LAN, Transparent or Network Address Translation
                </p>
                <p>
                  <span class="text-primary">Monitoring & Reporting:</span> Weekly Report, SIEM/SOC Integration
                </p>
                <br/>
                <p>
                   <strong>For more information, please see the <a class="text-primary" href="https://cdn.marlink.com/cyberguard/CyberGuard-UTM-Info-Sheet.pdf" target="_blank">CyberGuard UTM Info Sheet <i class="far fa-arrow-up-right-from-square"></i></a><br/>
                  If you are interested, please contact your sales representative / KAM</strong>
                </p>
            </div>
            `,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok, Thanks',
        });

        return; // does not redirect, but stays on same page you clicked from.
    }
}
